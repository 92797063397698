import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'
// import id from 'vuetify/lib/locale/id.js'
// import en from 'vuetify/lib/locale/en.js'
// import en from '../locales/en.json'
// import id from '../locales/id.json'
// import VueI18n from 'vue-i18n'

Vue.use(Vuetify)
// Vue.use(VueI18n)

// Vue.component('my-component', {
//   methods: {
//     changeLocale () {
//       this.$vuetify.lang.current = 'en'
//     }
//   }
// })

export default new Vuetify({
  options: {
    customProperties: true
  },
  defaultFontFamily: 'Poppins',
  // lang: {
  //   locales: { en, id },
  //   current: 'en'
  // },
  icons: {
    iconfont: 'mdi' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    themes: {
      light: {
        primary: '#3299d1',
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
        btnLogin: '#3299d1',
        btnBandingkan: '#00B2FF',
        cardColor: '#FFFFFF'
      },
      dark: {
        primary: colors.blue.lighten3,
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
        btnLogin: '#FFFFFF',
        btnBandingkan: '#00B2FF',
        cardColor: '#000000'
      },
      options: {
        customProperties: true
      },
      FontFace: {
        fontFamily: 'Poppins',
        src: 'url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap)'
      }
    },
    FontFace: {
      fontFamily: 'Poppins',
      src: 'url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap)'
    }
  }
})

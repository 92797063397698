import Swal from 'sweetalert2'
import store from '@/store'
const alerts = {
  sweetalertToast (icon, message) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    Toast.fire({
      icon: icon,
      title: message
    })
  },
  questionDelete (ID, module) {
    Swal.fire({
      title: 'Yakin dihapus?',
      text: 'Ini tidak dapat dikembalikan!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus!',
      cancelButtonText: 'Kembali',
      closeOnClickOutside: false,
      closeOnEsc: false
    }).then((result) => {
      if (result.isConfirmed) {
        store.dispatch(module, ID)
      }
    })
  },
  question2 (ID, module, title, text = 'Setelah submit data tidak dapat dikembalikan.', btnAction = '') {
    Swal.fire({
      title: title,
      text: text,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya' + btnAction,
      cancelButtonText: 'Kembali',
      closeOnClickOutside: false,
      closeOnEsc: false
    }).then((result) => {
      if (result.isConfirmed) {
        store.dispatch(module, ID)
      }
    })
  },
  questionConfirmation (payload, module, title, text = '', btnAction = '') {
    Swal.fire({
      title: title,
      text: text,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya' + btnAction,
      cancelButtonText: 'Kembali',
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        store.dispatch(module, payload)
      }
    })
  }
}
export default alerts

import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'

// modules
import home from './modules/home'
import auth from './modules/auth'
import paket from './modules/paket'
import loading from './modules/loading'
import product from './modules/product'

Vue.use(Vuex)

const secureLS = new SecureLS({
  encodingType: 'aes',
  encryptionSecret: 'vue-auth-persist'
})

// const authState = createPersistedState({
//   key: 'auth-persist-state',
//   paths: ['auth.formAuth'],
//   storage: {
//     getItem: (key) => secureLS.get(key),
//     setItem: (key, value) => secureLS.set(key, value),
//     removeItem: (key) => secureLS.remove(key)
//   }
// })

const userState = createPersistedState({
  key: 'auth-user-state',
  paths: ['auth.user'],
  storage: {
    getItem: (key) => secureLS.get(key),
    setItem: (key, value) => secureLS.set(key, value),
    removeItem: (key) => secureLS.remove(key)
  }
})

export default new Vuex.Store({
  modules: {
    home: home,
    auth: auth,
    paket: paket,
    loading: loading,
    product: product
  },
  plugins: [userState]
})

<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    innerWidth: window.innerWidth
  }),
  mounted () {
    // Tidak perlu lagi mendeteksi perangkat atau menyimpan ke localStorage
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize () {
      this.innerWidth = window.innerWidth
    }
  }
}
</script>

<style>
  @import './assets/styles/responsive.scss';
  @import './assets/styles/custom.css';
  @import './assets/styles/parallax.css';
</style>

<!-- <template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    innerWidth: window.innerWidth
  }),
  mounted () {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      localStorage.setItem('device', 'mobile/')
    } else {
      localStorage.setItem('device', 'desktop/')
    }
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize () {
      this.innerWidth = window.innerWidth
    }
  }
}
</script>
<style>
  @import './assets/styles/responsive.scss';
  @import './assets/styles/custom.css';
  @import './assets/styles/parallax.css';
</style> -->

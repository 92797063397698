import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'
// import 'vuetify/dist/vuetify.min.css'
import VueCarousel from 'vue-carousel'
import { VueperSlides, VueperSlide } from 'vueperslides'
// import 'vueperslides/dist/vueperslides.css'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'
import Scrollanimation from './animations/scrollanimation'
import Carousel3d from 'vue-carousel-3d'

Vue.directive('scrollanimation', Scrollanimation)
Vue.use(Carousel3d)
Vue.use(VueperSlides, VueperSlide)
Vue.use(Swiper, SwiperSlide)
Vue.use(VueCarousel)
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
